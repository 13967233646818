const ProgressBar = ({ color, progress, height = "15px" }) => {
  
  return (
    <div className="w-full bg-gray-200 rounded" style={{ height: height }}>
      <div
        className={`${color} rounded`}
        style={{ width: `${progress}%`, height: height }}
      ></div>
    </div>
  );
};

export default ProgressBar;