import React, { useState } from "react";
import "../Reset/Reset.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { PasswordReset } from "../../../../../data/auth/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CheckEmail from "./CheckEmail/CheckEmail";

const Reset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");

  function handleRequestMail(e) {
    e.preventDefault();
    dispatch(PasswordReset(email))
      .unwrap()
      .then(() => {
        toast.success("Password reset has been e-amil has been sent.");
        navigate("/reset/check-email", { state: { email } });
      })
      .catch((err) => {
        toast.error("Failed to refresh token. Please try again.");
      });
  }

  return (
    <>
      <div className="reset">
        <div className="reset-right-signup">
          <div className="reset-card">
            <div className="w-full flex items-start justify-center flex-col">
              <h3>Verify your email</h3>
              <span>Enter the email for your account</span>
            </div>
            <form
              action=""
              className=" flex flex-col items-center justify-center w-full "
              onSubmit={handleRequestMail}
            >
              <input
                className="reset-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter email"
              />
              <button type="submit">
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <span className="flex gap-2 items-center justify-center">
                    Verify Email
                  </span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
