import Footer from "../Layouts/Footer/Footer";
import Navbar from "../Layouts/Navbar/Navbar";
import heroobj from "../../assets/Images/heroobj.svg";
import obj from "../../assets/Images/obj.svg";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <img
        src={heroobj}
        alt={heroobj}
        className="heroobj bounce fixed top-[20px] right-0 opacity-25"
      />
      <img
        src={obj}
        alt={obj}
        className="heroobj fixed bounce bottom-10 -x`z-10 left-0 opacity-25"
      />
      <div className="lg:px-24 policy px-5  md:text-left flex flex-col gap-5 my-12">
        <h1>PRIVACY POLICY</h1>
        <p>
          This Privacy Policy describes how Jellosite (“we,” “us,” or “our”)
          collects, uses, and discloses your Personal Information when you visit
          or use the Jellosite platform (“Platform”).
        </p>
        <div className="flex flex-col gap-5">
          <h3>Contact Information</h3>
          <div className="flex flex-col gap-3">
            <p>
              If you have any questions, need further information about our
              privacy practices, or wish to file a complaint, you can contact
              us:
            </p>
            <ul>
              <li>
                <span className="font-bold">Email: </span>
                <a href="mailto:support@jellosite.com">support@jellosite.com</a>
              </li>
              <li>
                <span className="font-bold">Phone:</span> +234 9016 005 0377
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Collecting Personal Information</h3>
            <div className="flex flex-col gap-3">
              <p>
                We collect personal information to provide and improve our
                services, communicate with you, and comply with applicable legal
                requirements
              </p>
              <div className="flex flex-col gap-2">
                <h3>Account Information</h3>
                <ul>
                  <li>
                    <span className="font-semibold">
                      Purpose of collection:
                    </span>
                    To assist you with inquiries and troubleshoot any issues.
                  </li>
                  <li>
                    <span className="font-semibold">Source of collection:</span>{" "}
                    Collected when you contact us
                  </li>
                  <li>
                    <span className="font-semibold">
                      Personal Information collected:
                    </span>{" "}
                    Email address, phone number, and any details provided during
                    communication.
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-2">
                <h3>Transaction Information</h3>
                <ul>
                  <li>
                    All transactions are processed through Paystack. See their
                    privacy policy
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-2">
                <h3>Customer Support Information</h3>
                <ul>
                  <li>
                    <span className="font-semibold">
                      Purpose of collection:{" "}
                    </span>
                    To assist you with inquiries and troubleshoot any issues.
                  </li>
                  <li>
                    <span className="font-semibold">Source of collection:</span>{" "}
                    Collected when you contact us
                  </li>
                  <li>
                    <span className="font-semibold">
                      Personal Information collected:
                    </span>
                    Email address, phone number, and any details provided during
                    communication
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Minors</h3>
            <div className="flex flex-col gap-3">
              <p>
                Jellosite is not intended for individuals under the age of 18.
                We do not knowingly collect personal information from children.
                If you believe your child has provided us with personal
                information, please contact us to request deletion at
                support@jellosite.com. Except where explicitly prohibited by
                these Terms of Service or applicable law, we reserve the right
                to modify the Services or any part thereof for any reason, at
                any time, and without notice.
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <h3>How We Use Your Information</h3>
            <div className="flex flex-col gap-3">
              <p>
                We use your Personal Information for the following purposes:
              </p>
              <ol>
                <li>To provide, operate, and improve our services</li>
                <li>To process payments and manage your account.</li>
                <li>
                  To comply with legal obligations and enforce our Terms of
                  Service.
                </li>
                <li>
                  To personalize your experience and provide targeted
                  advertisements.
                </li>
              </ol>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <h3>Sharing Personal Information</h3>
            <div className="flex flex-col gap-3">
              <p>
                We may share your personal information with the following
                parties:
              </p>
              <ol>
                <li>
                  <span className="font-semibold">Service Providers: </span>For
                  payment processing, hosting, analytics, and customer support.
                </li>
                <li>
                  <span className="font-semibold">
                    Compliance and Legal Obligations:{" "}
                  </span>
                  To comply with laws, respond to lawful requests, or protect
                  our rights.
                </li>
                <li>
                  <span className="font-semibold">Advertising Partners: </span>
                  To deliver targeted ads based on your activity on the
                  Platform.
                </li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Behavioral Advertising</h3>
            <p>
              We use Personal Information to provide you with personalized
              advertisements and marketing messages that we believe will be of
              interest to you.
            </p>
            <p className="text-semibold">
              Third-party advertising tools we use include:
            </p>
            <div className="flex flex-col gap-3">
              <ul>
                <li>
                  <span className="font-semibold"> Google Analytics: </span>
                  Helps analyze Platform usage. You can opt out here:{" "}
                  <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                  </a>
                </li>
                <li>
                  <span className="font-semibold">Facebook Ads: </span>
                  Learn more about ad settings here:
                  <a href="https://www.facebook.com/settings/?tab=ads">
                    https://www.facebook.com/settings/?tab=ads
                  </a>
                </li>
              </ul>
            </div>
            <p>
              You can opt out of targeted advertising by visiting the Digital
              Advertising Alliance’s opt-out portal:{" "}
              <a href="https://optout.aboutads.info/">
                https://optout.aboutads.info/.
              </a>
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Your Rights</h3>
            <h4>For European Economic Area (EEA) Residents</h4>
            <p>
              If you are located in the EEA, you have the following rights under
              the General Data Protection Regulation (GDPR):
            </p>
            <div className="flex flex-col gap-3">
              <ol>
                <li>
                  <span className="font-semibold"> Access: </span> Request
                  access to the personal information we hold about you.
                </li>
                <li>
                  <span className="font-semibold">Correction: </span> Request
                  that we correct or update your personal information.
                </li>
                <li>
                  <span className="font-semibold">Deletion: </span>Request that
                  we delete your personal information
                </li>
                <li>
                  <span className="font-semibold">Data Portability: </span>
                  Request a copy of your personal information in a portable
                  format
                </li>
                <li>
                  <span className="font-semibold">
                    Restriction and Objection:
                  </span>
                  Restrict processing or object to certain uses of your data.
                </li>
              </ol>
            </div>
            <p>
              To exercise these rights, please contact us at
              <a href="support@jellosite.com"> support@jellosite.com.</a>
            </p>
            <h4>For California Residents</h4>
            <p>
              If you are a resident of California, you have the following rights
              under the California Consumer Privacy Act (CCPA):
            </p>
            <div className="flex flex-col gap-3">
              <ol>
                <li>
                  <span className="font-semibold">Right to Know: </span>
                  Request information about the personal data we collect and how
                  we use it.
                </li>
                <li>
                  <span className="font-semibold"> Right to Delete: </span>
                  Request the deletion of your personal information.
                </li>
                <li>
                  <span className="font-semibold">Right to Opt Out: </span>
                  Opt out of the sale of your personal information
                </li>
              </ol>
            </div>
            <p>
              To exercise these rights, please contact us at
              <a href="support@jellosite.com"> support@jellosite.com.</a>
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Cookies and Tracking Technologies</h3>
            <p>We use cookies and similar technologies to:</p>
            <div className="flex flex-col gap-3">
              <ol>
                <li>Ensure the Platform functions properly.</li>
                <li>Analyze and improve performance.</li>
                <li>Provide personalized advertisements.</li>
              </ol>
              <div className="flex flex-col gap-2">
                <h4>Managing Cookies</h4>
                <p>
                  Most browsers allow you to block or delete cookies. Note that
                  disabling cookies may limit certain features of the Platform.
                  For more information, visit{" "}
                  <a href="www.allaboutcookies.org">www.allaboutcookies.org.</a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Data Retention</h3>
            <div className="flex flex-col gap-3">
              <p>
                We retain your Personal Information for as long as necessary to
                fulfill the purposes outlined in this Privacy Policy, unless
                required by law to keep it longer.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Security</h3>
            <div className="flex flex-col gap-3">
              <p>
                We implement technical and organizational measures to protect
                your personal information. However, no system is 100% secure,
                and you use the Platform at your own risk
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Changes to This Privacy Policy</h3>
            <div className="flex flex-col gap-3">
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for legal reasons. Changes will be
                posted on this page with an updated effective date
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Complaints</h3>
            <div className="flex flex-col gap-3">
              If you have any concerns about our privacy practices, you can file
              a complaint by contacting us at support@jellosite.com. If you are
              not satisfied with our response, you may lodge a complaint with
              your local data protection authority.
            </div>
          </div>
        </div>
        Last Updated: January 7, 2025
        <div>
          <p>Jellosite</p>
          <p>Abuja, Nigeria</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
