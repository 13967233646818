import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../CheckEmail/CheckEmail.scss";

const CheckEmail = () => {
  const location = useLocation();
  const email = location.state?.email;

  return (
    <>
      <div className="check">
        <div className="check-right-signup">
          <div className="check-card">
            <div className="w-full flex items-start justify-center flex-col">
              <h3>Check your email</h3>
              <span>
                A password reset email has been sent to:{" "}
                <span className=" font-bold">{email}</span>
              </span>
              <hr className="w-full h-[1px] bg-black border-0 mt-3" />
            </div>
            <form
              action=""
              className=" flex flex-col items-center justify-center w-full "
            >
              <button>
                <span className="flex gap-2 items-center justify-center">
                  Go to mail
                  <FontAwesomeIcon icon={faInbox} color="white" />
                </span>
              </button>
              <div>
                <span>Haven't seen email or wrong email?</span>
                <span className="text-[#7e57c2] -mt-2 cursor-pointer ">
                  <Link to="/reset" className="no-underline">
                    <span> Send new or edit email</span>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckEmail;
