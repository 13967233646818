import axios from "axios";

const API_URL = import.meta.env.VITE_API_URL;

const TokenVerify = async (key) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/register/verify-email/`,
      {
        key: key,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error.response);
    throw error;
  }
};

const TokenRefresh = async (email) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/register/resend-email/`,
      {
        email: email,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const PasswordReset = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/password/reset/`, {
      email: email,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const PasswordResetConfirm = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/password/reset/confirm/`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const verifyResetService = {
  TokenVerify,
  TokenRefresh,
  PasswordReset,
  PasswordResetConfirm,
};

export default verifyResetService;
