import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PasswordResetConfirm } from "../../../../../data/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import "../Update/Update.scss";
import { toast } from "react-toastify";

const Update = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { uid, token } = useParams();

  const { loading } = useSelector((state) => state.auth);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const data = {
    new_password1: password,
    new_password2: confirmPassword,
    uid: uid,
    token: token,
  };

  function handleShow() {
    setShow(!show);
  }
  function handleShow2() {
    setShow2(!show2);
  }

  function handleResetPassword(e) {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      dispatch(PasswordResetConfirm(data))
        .unwrap()
        .then(() => {
          toast.success("Password reset successfull.");
          navigate("/login");
        })
        .catch((err) => {
          toast.error("Failed to reset password. Please try again.");
        });
    }
  }

  return (
    <>
      <div className="update">
        <div className="update-right-signup">
          <div className="update-card">
            <div className="w-full flex items-start justify-center flex-col">
              <h3>Reset your password</h3>
              <span>Enter and confirm your new password</span>
            </div>
            <form
              action=""
              className=" flex flex-col items-center justify-center w-full "
              onSubmit={handleResetPassword}
            >
              <div className="flex flex-col items-start justify-center w-full gap-4">
                <label htmlFor="" className="-mb-3">
                  New Password
                </label>
                <div className="flex items-center justify-start gap-2 w-full">
                  <input
                    className="update-input"
                    type={show ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter new password"
                  />
                  <FontAwesomeIcon
                    icon={show ? faEye : faEyeSlash}
                    onClick={handleShow}
                  />
                </div>

                <label htmlFor="" className="-mb-3">
                  Confirm New Password
                </label>
                <div className="flex items-center justify-start gap-2 w-full">
                  <input
                    className="update-input"
                    type={show2 ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    placeholder="Confirm new password"
                  />
                  <FontAwesomeIcon
                    icon={show2 ? faEye : faEyeSlash}
                    onClick={handleShow2}
                  />
                </div>
              </div>

              <button type="submit">
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <span className="flex gap-2 items-center justify-center">
                    Reset Password
                  </span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
