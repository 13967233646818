export function obscureEmail(email) {
    if (!email) return ""; // Return empty string if email is not
    const [localPart, domain] = email.split("@"); // Split email into local part and domain
    const localLength = localPart.length;

    if (localLength <= 4) {
        const obscuredPart = "*".repeat(localLength - 1);
        // For very short emails, obscure less
        return `${localPart[0]}${obscuredPart}@${domain}`;
    }

    // Determine the parts to keep visible
    const startLength = Math.ceil(localLength / 3);
    const endLength = Math.ceil(localLength / 3);

    const start = localPart.slice(0, startLength); // First part to keep
    const end = localPart.slice(-endLength); // Last part to keep

    // Calculate the number of hidden characters
    const numHiddenChars = localLength - (startLength + endLength);

    // Create the obscured string with the same number of `*` as hidden chars
    const obscuredPart = "*".repeat(numHiddenChars);

    return `${start}${obscuredPart}${end}@${domain}`;
}
