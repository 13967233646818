import React, { useEffect } from "react";
import "../Verify/Verify.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TokenRefresh } from "../../../../../data/auth/authSlice";
import { toast } from "react-toastify";
import { obscureEmail } from "../../../../../data/utils/helpers";

const Verify = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const email = localStorage.getItem("user_email");

  useEffect(() => {
    if (!email) {
      navigate("/signup");
    }
  }, [])

  function handleTokenRefresh() {
    dispatch(TokenRefresh(email))
      .unwrap()
      .then(() => {
        toast.success("Token refreshed successfully! Please check your email.");
      })
      .catch((err) => {
        toast.error("Failed to refresh token. Please try again.");
      });
  }

  return (
    <>
      <div className="verify">
        <div className="verify-right-signup">
          <div className="verify-card">
            <div className="w-full text-center">
              <h2>Verify your email</h2>
              <span>A verification link has been sent to </span>
              <span
                className="text-[#7e57c2]"
              >{obscureEmail(email)}</span>
            </div>
            <div>
              <span>Haven't seen email?</span>
              <span
                className="text-[#7e57c2] -mt-2 cursor-pointer"
                onClick={handleTokenRefresh}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <span> Resend</span>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
