import { useState, useCallback, useEffect } from "react";
import Navbar from "../../Layouts/Navbar/Navbar";
import { useSelector } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
import { FaCircleInfo } from "react-icons/fa6";
import "./onboard.scss";
import { useCreateNewSiteMutation } from "../../../data/api/createSiteApi";
import { useLazyCheckSiteQuery } from "../../../data/api/generalapi";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// import storenormal from "@assets/Icons/clarity_store-solid (2).svg";
// import storehover from "@assets/Icons/hover_clarity_store-solid (1).svg";
// import Schoolnormal from "@assets/Icons/mdi_school-outline.svg";
// import schoolHover from "@assets/Icons/hover mdi_school-outline (1).svg";
// import dispatchnormal from "@assets/Icons/shipping truck.svg";
// import dispatchhover from "@assets/Icons/hover_Transport.svg";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Onboard = () => {
  const [hoveredSection, setHoveredSection] = useState(null);
  const [showSection2, setShowSection2] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [slug, setSlug] = useState("");
  const [checkType, setCheckType] = useState("");
  const [availability, setAvailability] = useState(null);
  const navigate = useNavigate();

  const access = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.userInfo.first_name);
  const [createNewSite, { isLoading }] = useCreateNewSiteMutation();
  const [
    checkSite,
    { data: checkSiteData, isFetching: isChecking, isSuccess, isError },
  ] = useLazyCheckSiteQuery();
  const isDesiredType = ["ecommerce", "school", "dispatchServices"].includes(
    checkType
  );
  const canCreate = storeName && slug && !isLoading && availability;

  useEffect(() => {
    if (isSuccess) {
      if (!validateSlug(slug)) {
        setAvailability(false);
        console.log("Invalid slug");
        return;
      }
      setAvailability(checkSiteData.available);
    }
  }, [isSuccess, checkSiteData, slug]);

  const handleSectionHover = useCallback((sectionIndex) => {
    setHoveredSection(sectionIndex);
  }, []);

  const handleSectionLeave = useCallback(() => {
    setHoveredSection(null);
  }, []);

  const handleGoBackClick = useCallback(() => {
    setCheckType("");
    history.back();
  }, []);

  const handleLogout = () => {
    Cookies.remove("jellosite-accessToken", {
      domain: `.${import.meta.env.VITE_DOMAIN_NAME}`,
    });
    navigate("/login");
  };

  const handleSectionClick = useCallback((name) => {
    setCheckType(name);
    setShowSection2(true);
  }, []);

  const validateSlug = (slug) => {
    const slugRegex = /^[-a-zA-Z0-9_]+$/;
    return slugRegex.test(slug);
  };
  const debouncedCheckSite = useCallback(
    debounce((slugValue) => {
      checkSite({ slug: slugValue, access });
    }, 300),
    [checkSite, access]
  );

  const handleInputChange = useCallback(
    (event) => {
      const inputValue = event.target.value;
      setAvailability(false);
      setSlug(inputValue);
      debouncedCheckSite(inputValue);
    },
    [debouncedCheckSite]
  );

  const handleCreateSite = async (e) => {
    e.preventDefault();

    const data = {
      name: storeName,
      slug,
      site_type: "ecommerce",
      // is_disabled: false,
      // is_deleted: false,
    };

    try {
      const response = await createNewSite({ body: data, access });
      if (response.error) {
        toast.error("An error occurred");
        return;
      }

      toast.success("Site created, Let's take you to Dashboard");
      const redirectUrl = new URLSearchParams(location.search).get("redirect");
      Cookies.set("jellosite-current-slug", response.data.data, {
        domain: `.${import.meta.env.VITE_DOMAIN_NAME}`,
      });

      if (redirectUrl) {
        window.location.href = redirectUrl;
        return;
      }
      window.location.href = `${import.meta.env.VITE_DASHBOARD_URL}/overview`;
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className="w-full flex flex-col h-full relative overflow-hidden">
        {/* <div
          className={`flex section-1 flex-col p-4 pb-5 justify-center duration-200 gap-6 ${
            isDesiredType ? "-translate-x-full z-10 absolute" : ""
          }`}
        >
          <h1 className="md:text-3xl text-lg text-center font-medium">
            Hi {user ? user : "friend"}👋🏻 <br /> What kind of business website
            do you want to create?
          </h1>
          <div className="flex gap-10 flex-wrap justify-center">
            {[storenormal, Schoolnormal, dispatchnormal].map((img, index) => (
              <div
                key={index}
                className={`w-full md:w-[160px] font-medium hover:shadow-custom duration-300 ease-in hover:border-0 cursor-pointer gap-2 py-3 md:h-[130px] business rounded-lg flex md:flex-col md:justify-center items-center border ${
                  hoveredSection === index ? "hovered-section" : ""
                }`}
                onMouseEnter={() => handleSectionHover(index)}
                onMouseLeave={handleSectionLeave}
                onClick={() =>
                  handleSectionClick(
                    ["ecommerce", "school", "dispatchServices"][index]
                  )
                }
              >
                <img
                  className="scale-75 md:scale-100"
                  src={
                    hoveredSection === index
                      ? [storehover, schoolHover, dispatchhover][index]
                      : img
                  }
                  alt="service"
                />

                {["Regular Store", "School", "Dispatch Service"][index]}
              </div>
            ))}
          </div>
        </div> */}

        <div
          className={`section-2 justify-center duration-200 flex items-center w-full h-[85vh] transition-transform transform translate-x-full absolute"
          }`}
        >
          <form className="flex rounded flex-col gap-5 lg:shadow-custom p-8 w-full md:w-1/2 lg:w-1/3 max-h-[550px] overflow-auto">
            <div className="flex">
              <p
                onClick={handleGoBackClick}
                className="text-xs flex gap-1 items-center cursor-pointer"
              >
                <IoMdArrowBack size={15} /> Go Back
              </p>

              <a
                onClick={handleLogout}
                className="text-xs cursor-pointer ml-auto"
              >
                Logout
              </a>
            </div>
            <h2 className="text-xl font-bold">Create A Site</h2>
            <span className="w-full flex">
              <input
                className="w-full h-[56px] rounded py-3 px-4 outline outline-[1px] outline-[#E4E4E7]"
                type="text"
                required
                placeholder="Store Name"
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              />
            </span>

            <span className="relative">
              <input
                className="w-full h-[56px] rounded py-3 px-4 outline outline-[1px] outline-[#E4E4E7]"
                type="text"
                placeholder="Store-site"
                required={true}
                value={slug}
                onChange={handleInputChange}
              />
              {slug && (
                <span
                  className={`font-medium ${
                    isChecking
                      ? "text-yellow-500"
                      : availability
                      ? "text-green-700"
                      : "text-red-900"
                  }`}
                >
                  {isError
                    ? "Couldn't check availability, Check your internet"
                    : isChecking
                    ? "Checking availability..."
                    : availability
                    ? `${slug} is available`
                    : `${slug} is unavailable`}
                </span>
              )}
              <span className="text-sm flex items-center gap-1 absolute right-3 font-medium top-5">
                <p>.{import.meta.env.VITE_STORE_DOMAIN_NAME}</p>
                <span className="opacity-70 relative group">
                  <FaCircleInfo />
                  <span className="absolute group-hover:flex hidden -right-5 p-1 text-xs text-white rounded-sm w-[200px] top-full bg-slate-500">
                    a slug like fashion will give a store like fashion.
                    {import.meta.env.VITE_STORE_DOMAIN_NAME}
                  </span>
                </span>
              </span>
            </span>

            <button
              className="rounded-lg disabled:opacity-40 h-[56px] py-3 text-white bg-[#7E57C2]"
              type="text"
              disabled={!canCreate}
              onClick={handleCreateSite}
            >
              {isLoading ? "Creating site" : "Create Store"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Onboard;
