import React from "react";
import { Link } from "react-router-dom";
import notfoundimg from "../../assets/Images/404.png";

const NotFound = () => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <img className="w-[350px] " src={notfoundimg} alt="404" />
      <h1 className="lg:text-6xl -mt-10 md:text-4xl text-3xl my-3 font-bold font-kanit">
        Page Not Found
      </h1>
      <p className="font-medium text-">
        If you look harder, you'd find what you are searching for.
      </p>
      <Link className="font-medium text-purple-600" to="/">
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
