import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faWarning,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Login/Login.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../Layouts/Navbar/Navbar";
import { userLogin } from "../../../../data/auth/authAction";
import { toast } from "react-toastify";
import { resetLogin } from "../../../../data/auth/authSlice";
import { GoogleLoginButton } from "../Component/GoogleAuth";

const Login = () => {
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { loading, error, isLoggedin } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    dispatch(resetLogin());

    if (isLoggedin) {
      if (user && user.sites.length < 1) {
        navigate("/onboarding");
      } else {
        navigate("/sites");
      }
    }
  }, [isLoggedin, user, location.search, navigate, dispatch]);

  const handleShow = () => {
    setShow(!show);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      username: emailInput.toLowerCase(),
      password: passwordInput,
    };
    try {
      dispatch(userLogin(data));
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="login">
        <div className="auth-left-login">
          <h1>Create your website in a few clicks</h1>
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} color="#7F56D9" />
              <span>Fully responsive</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} color="#7F56D9" />
              <span>Modular design</span>
            </li>
          </ul>
        </div>
        <div className="auth-right-login">
          <div className="login-card">
            <h2>Sign In to JelloSite</h2>
            <form onSubmit={handleSubmit}>
              {error && (
                <span className="error-msg">
                  <FontAwesomeIcon icon={faWarning} color="red" />{" "}
                  {error === "Request failed with status code 400"
                    ? "Invalid Email or password"
                    : "Check Internet Connection and Try again"}
                </span>
              )}
              <div className="input">
                <input
                  required
                  className="pl-2"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  type="email"
                  placeholder="Email Address"
                />
                <div className="input-pass">
                  <input
                    required
                    className="pl-2"
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    type={show ? "text" : "password"}
                    placeholder="Password"
                  />
                  <FontAwesomeIcon
                    icon={show ? faEye : faEyeSlash}
                    onClick={handleShow}
                  />
                </div>
              </div>
              <div className="rem-pass">
                <div className="rem">
                  <input type="checkbox" />
                  <span>Remember me</span>
                </div>
                <Link to="/reset" className="text-black">
                  <span>Forgot Password?</span>
                </Link>
              </div>
              <button type="submit">
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Login"}
              </button>
              {/* <div className="google-btn -mt-2">
                <GoogleLoginButton />
              </div> */}
            </form>
            <span>
              Don&apos;t have an account?{" "}
              <Link to="/signup" style={{ color: "#7F56D9" }}>
                Sign Up
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
