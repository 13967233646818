import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin } from "./authAction";
import { domainName } from "../utils/constants";
import Cookies from "js-cookie";
import verifyResetService from "./verify-reset/verifyResetService";
import axios from "axios";
// import { authApiSlice } from "./authApi";

// Retrieve data from local storage if available
// const storedAccessToken = localStorage.getItem("accessToken");
const storedUserInfoString = Cookies.get("jellosite-user");
const storedAccessToken = Cookies.get("jellosite-authToken");
const storedUserInfo = storedUserInfoString
  ? JSON.parse(storedUserInfoString)
  : null;

// Define the initial state
const initialState = {
  loading: false,
  userInfo: storedUserInfo,
  accessToken: storedAccessToken || null,
  error: null,
  success: false,
  isLoggedin: false,
  data: [],
};

const API_URL = import.meta.env.VITE_API_URL;

export const TokenVerify = createAsyncThunk(
  "auth/verify",
  async (key, thunkApi) => {
    try {
      return await verifyResetService.TokenVerify(key);
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const TokenRefresh = createAsyncThunk(
  "auth/verify-refresh",
  async (email, thunkApi) => {
    try {
      return await verifyResetService.TokenRefresh(email);
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const PasswordReset = createAsyncThunk(
  "auth/pass-reset",
  async (email, thunkApi) => {
    try {
      return await verifyResetService.PasswordReset(email);
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const PasswordResetConfirm = createAsyncThunk(
  "auth/pass-confirm",
  async (data, thunkApi) => {
    try {
      return await verifyResetService.PasswordResetConfirm(data);
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  "auth/loginWithGoogle",
  async (accessToken, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/api/auth/google/login/`, {
        access_token: accessToken,
      });

      return response.data; // Ensure this contains `accessToken` and `userInfo`.
    } catch (error) {
      console.error("Login with Google API error:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Create the authSlice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetLogin(state) {
      state.isLoggedin = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;

        // Update state with the relevant data
        state.accessToken = payload.access || null; // Ensure a value or null
        state.userInfo = payload.data || null; // Ensure a value or null

        // Save user information to local storage

        Cookies.set("jellosite-authToken", payload.access, {
          domain: `.${domainName}`,
        });
        Cookies.set("jellosite-authToken", payload.access, {
          domain: `.${import.meta.env.VITE_STORE_DOMAIN_NAME}`,
        });
        Cookies.set("jellosite-user", JSON.stringify(payload.user || null), {
          domain: `.${domainName}`,
        });
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
        state.isLoggedin = true;
        // Assuming payload is already a JSON object

        const responseData = payload;

        // Update state with the relevant data
        state.accessToken = responseData.data.access || null; // Ensure a value or null
        state.userInfo = responseData.data.user || null; // Ensure a value or null

        // Save user information to local storage

        Cookies.set("jellosite-authToken", payload.data.access, {
          domain: `.${domainName}`,
        });
        Cookies.set(
          "jellosite-user",
          JSON.stringify(payload.data.user || null),
          {
            domain: `.${domainName}`,
          }
        );
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(loginWithGoogle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedin = true;
        state.accessToken = action.payload.access || null; // Update based on API response.
        state.userInfo = action.payload.user || null; // Update based on API response.

        Cookies.set("jellosite-authToken", state.accessToken, {
          domain: `.${domainName}`,
        });
        Cookies.set("jellosite-user", JSON.stringify(state.userInfo), {
          domain: `.${domainName}`,
        });
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "An error occurred.";
      })
      .addCase(TokenVerify.pending, (state) => {
        state.loading = true;
      })
      .addCase(TokenVerify.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(TokenVerify.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(TokenRefresh.pending, (state) => {
        state.loading = true;
      })
      .addCase(TokenRefresh.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(TokenRefresh.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(PasswordReset.pending, (state) => {
        state.loading = true;
      })
      .addCase(PasswordReset.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(PasswordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(PasswordResetConfirm.pending, (state) => {
        state.loading = true;
      })
      .addCase(PasswordResetConfirm.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload.theme;
      })
      .addCase(PasswordResetConfirm.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      });
  },
});

export const { resetLogin } = authSlice.actions;
export default authSlice.reducer;
