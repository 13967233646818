import { useSelector } from "react-redux";
import nike from "@assets/Images/nike.png";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useGetSiteQuery } from "../../../data/api/generalapi";
import "./sitescss.css";
import { domainName } from "../../../data/utils/constants";

const Sites = () => {
  const access = useSelector((state) => state.auth.accessToken);
  const { data: sites, isLoading } = useGetSiteQuery(access);
  function createSlug(slug) {
    Cookies.set("jellosite-current-slug", slug, {
      domain: `.${domainName}`,
    });

    window.location.href = import.meta.env.VITE_DASHBOARD_URL;
  }
  return (
    <div className="flex w-full h-[100vh] justify-center md:items-center">
      <div className="lg:shadow-custom md:max-h-[70vh] md:overflow-y-auto w-full md:w-2/3 lg:w-1/3 flex flex-col gap-4 p-8">
        <div className="flex w-full justify-between items-center">
          <h1 className="text-xl font-bold">Your Sites</h1>
          <button className="p-3 rounded-md bg-[#7E57C2] text-white font-medium">
            <Link to={"/onboarding"} className="text-white no-underline">
              + Add business
            </Link>
          </button>
        </div>
        <div className="flex flex-col items-center  justify-center w-full gap-6">
          {sites ? (
            sites.map((site, index) => (
              <div
                onClick={() => createSlug(site.slug)}
                className="flex cursor-pointer hover:bg-[#7E57C2]/10 rounded-md p-3 w-full items-center justify-between"
                key={index}
              >
                <div className="flex items-center gap-3">
                  <img src={nike} className="w-14 h-16" alt="logo" />
                  <span className="flex flex-col">
                    <p className="font-bold capitalize">{site.name}</p>
                    <p>
                      {" "}
                      {site.slug}.{import.meta.env.VITE_STORE_DOMAIN_NAME}
                    </p>
                  </span>
                </div>
                <span
                  className={`rounded-full text-white px-3 py-1  ${
                    site.is_subscribed ?  "bg-[#20AD03]" : "bg-[#E1E1E1]"
                  }`}
                >
                  {site.is_subscribed ? "Active": "Inactive"}
                </span>
              </div>
            ))
          ) : isLoading ? (
            <div className="loader"></div>
          ) : (
            <span>Failed to get Sites.</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sites;
